import { createSlice } from "@reduxjs/toolkit";
import { CareFlowResponseType } from "../types/Visits/CareFlowResponseType";
import { isFalsy } from "../helpers/helpers";
import GetVisitResponseType from "../types/Visits/GetVisitResponseType";
import VisitStatusEnum from "../enums/Calendaring/Visits/VisitStatusEnum";

interface ElapsedTime {
  section_id: string;
  subsection_id: string;
  actual_time_seconds: number;
}

interface StateType {
  visitId: string;
  selectedSection: string;
  selectedSubSection: string;
  elapsedTime: ElapsedTime[];
  answers: { [id: string]: string | boolean };
  ticking: boolean;
  generatedNotes: string;
}

const INITIAL_STATE: StateType = {
  visitId: null,
  selectedSection: null,
  selectedSubSection: null,
  elapsedTime: [],
  answers: {},
  ticking: true,
  generatedNotes: null
};

const converSectionsToAnswersArray = (careFlow: CareFlowResponseType) => {
  const answers = {};
  careFlow?.sections?.forEach((section) => {
    section?.subsections?.forEach((subSection) => {
      subSection?.fields?.forEach((field) => {
        if (!isFalsy(field?.value)) {
          answers[field?.field_id] = field?.value;
        }
      });
    });
  });

  return answers;
};

const processTime = (
  elapsedTime: ElapsedTime[],
  actual_time_seconds: number,
  currentSection: string,
  currentSubSection: string
) => {
  const sectionArray = elapsedTime ? [...elapsedTime] : [];
  let sectionIndex = sectionArray.findIndex((item) => {
    if (currentSubSection) {
      return item.subsection_id === currentSubSection;
    } else return item.section_id === currentSection;
  });

  if (sectionIndex === -1) {
    sectionIndex = sectionArray.length;
    sectionArray.push({
      section_id: currentSection,
      subsection_id: currentSubSection,
      actual_time_seconds: 0
    });
  }

  sectionArray[sectionIndex].actual_time_seconds += actual_time_seconds;
  return sectionArray;
};

export const visitsSlice = createSlice({
  name: "visits",
  initialState: INITIAL_STATE,
  reducers: {
    initialize: (state, action) => {
      const visit = action.payload as GetVisitResponseType;
      const careFlow = visit.care_flow;

      state.visitId = visit.visit_id;
      state.answers = converSectionsToAnswersArray(careFlow);

      const elapsedTime: ElapsedTime[] = [];
      careFlow?.sections?.forEach((section) => {
        section.subsections.forEach((subSection) => {
          elapsedTime.push({
            section_id: section.section_id,
            subsection_id: subSection.section_id,
            actual_time_seconds: subSection.actual_time_seconds ?? 0
          });
        });
      });

      state.ticking = visit.status === VisitStatusEnum.IN_PROGRESS;
      state.elapsedTime = elapsedTime;
      state.selectedSection = careFlow?.current_section;
      state.selectedSubSection = null;
    },
    setSelectedSection: (state, action) => {
      const { section_id, subsection_id } = action.payload;

      state.selectedSection = section_id;
      state.selectedSubSection = subsection_id;
    },
    addTime: (state, action) => {
      const { section_id, actual_time_seconds } = action.payload;
      if (!state.ticking) return;

      const currentSection = section_id ?? state.selectedSection;
      if (!currentSection) return;

      state.elapsedTime = processTime(
        state.elapsedTime,
        actual_time_seconds,
        currentSection,
        state.selectedSubSection
      );
    },
    setAnswer: (state, action) => {
      const { id, value } = action.payload;
      const clonedAnswers = { ...state.answers };
      clonedAnswers[id] = value;
      state.answers = clonedAnswers;
    },
    stopTicking: (state) => {
      state.ticking = false;
    },
    setGeneratedNotes: (state, action) => {
      state.generatedNotes = action.payload;
    }
  }
});

export const {
  initialize,
  setSelectedSection,
  addTime,
  setAnswer,
  stopTicking,
  setGeneratedNotes
} = visitsSlice.actions;

export default visitsSlice.reducer;
